@import '../docs-variables';

#{$ds-scope-selector} .doc-theme-changer-fix-section {
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  background: var(--color-1);
  padding: 8px;
  border-radius: 8px;
  box-shadow: var(--shadow-sm);
  width: 170px;

  &.doc-page {
    bottom: unset;
    left: unset;
    top: 16px;
    right: 16px;
  }
}

#{$ds-scope-selector} .toggle-theme-wrapper {
  .changer-label {
    background-color: #263238 !important;
    width: 50px;
    height: 24px;
    padding: 5px;
    border-radius: 50px;
    position: relative;
    display: flex;
    transition: background 0.2s linear;
    justify-content: space-between;
    align-items: center;

    .fas {
      line-height: unset;
    }
  }

  .ball {
    background-color: #f5f5f5 !important;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 2px;
    transition: transform 0.2s linear;
  }

  &.dark {
    .changer-label {
      background-color: #fff !important;
    }

    .ball {
      background-color: #263238 !important;
    }
  }

  .toggle-theme {
    opacity: 0;
    position: absolute;
  }

  .toggle-theme:checked + .changer-label .ball {
    transform: translateX(24px);
  }

  .fa-sun {
    color: #ffc400;
  }

  .fa-moon {
    color: #bdbdbd;
  }
}
