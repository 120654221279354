// Global utility classes
#{$ds-scope-selector} {
  .d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-inline-block {
    display: inline-block !important;
  }

  .justify-content-between {
    justify-content: space-between !important;
  }

  .justify-center {
    justify-content: center !important;
  }

  .align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .flex-space-between-center {
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-center {
    text-align: center !important;
  }

  .text-primary {
    color: $color-primary !important;
  }

  .text-color {
    color: $color-text !important;
  }

  .text-muted {
    color: $color-text-muted !important;
  }

  .text-disabled {
    color: $color-text-disabled !important;
  }

  .text-success {
    color: $color-success !important;
  }

  .text-warning {
    color: $color-warning !important;
  }

  .text-danger {
    color: $color-danger !important;
  }

  .text-nowrap {
    white-space: nowrap !important;
  }

  .text-left {
    text-align: left !important;
  }

  .text-right {
    text-align: right !important;
  }

  .link-wrapping-button {
    text-decoration: none !important;
  }

  .mt-05 {
    margin-top: spacing(0.5rem) !important;
  }

  .mb-05 {
    margin-bottom: spacing(0.5rem) !important;
  }

  .mr-05 {
    margin-right: spacing(0.5rem) !important;
  }

  .ml-05 {
    margin-left: spacing(0.5rem) !important;
  }

  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.25rem;
    height: 3.25rem;

    svg {
      font-size: 2.5rem;
    }
  }

  .label-style {
    @include label-styles();
  }

  @for $i from 0 through 6 {
    .mt-#{$i} {
      margin-top: spacing(#{$i}rem) !important;
    }

    .mb-#{$i} {
      margin-bottom: spacing(#{$i}rem) !important;
    }

    .mr-#{$i} {
      margin-right: spacing(#{$i}rem) !important;
    }

    .ml-#{$i} {
      margin-left: spacing(#{$i}rem) !important;
    }
  }

  @media (min-width: $mobile-min-width) {
    @for $i from 0 through 6 {
      .mt-sm-#{$i} {
        margin-top: spacing(#{$i}rem) !important;
      }

      .mb-sm-#{$i} {
        margin-bottom: spacing(#{$i}rem) !important;
      }

      .mr-sm-#{$i} {
        margin-right: spacing(#{$i}rem) !important;
      }

      .ml-sm-#{$i} {
        margin-left: spacing(#{$i}rem) !important;
      }
    }
  }

  @media (min-width: $tablet-min-width) {
    @for $i from 0 through 6 {
      .mt-md-#{$i} {
        margin-top: spacing(#{$i}rem) !important;
      }

      .mb-md-#{$i} {
        margin-bottom: spacing(#{$i}rem) !important;
      }

      .mr-md-#{$i} {
        margin-right: spacing(#{$i}rem) !important;
      }

      .ml-md-#{$i} {
        margin-left: spacing(#{$i}rem) !important;
      }
    }
  }

  @media (min-width: $desktop-min-width) {
    @for $i from 0 through 6 {
      .mt-lg-#{$i} {
        margin-top: spacing(#{$i}rem) !important;
      }

      .mb-lg-#{$i} {
        margin-bottom: spacing(#{$i}rem) !important;
      }

      .mr-lg-#{$i} {
        margin-right: spacing(#{$i}rem) !important;
      }

      .ml-lg-#{$i} {
        margin-left: spacing(#{$i}rem) !important;
      }
    }
  }

  @for $i from 1 through 4 {
    .w-#{$i*25} {
      width: #{$i*25%} !important;
    }
  }

  .w-auto {
    width: auto !important;
  }

  @media (min-width: $mobile-min-width) {
    @for $i from 1 through 4 {
      .w-sm-#{$i*25} {
        width: #{$i*25%} !important;
      }
    }

    .w-sm-auto {
      width: auto !important;
    }
  }

  @media (min-width: $tablet-min-width) {
    @for $i from 1 through 4 {
      .w-md-#{$i*25} {
        width: #{$i*25%} !important;
      }
    }

    .w-md-auto {
      width: auto !important;
    }
  }

  @media (min-width: $desktop-min-width) {
    @for $i from 1 through 4 {
      .w-lg-#{$i*25} {
        width: #{$i*25%} !important;
      }
    }

    .w-lg-auto {
      width: auto !important;
    }
  }

  .d-block {
    display: block !important;
  }

  .d-none {
    display: none !important;
  }

  @media (min-width: $mobile-min-width) {
    .d-sm-block {
      display: block !important;
    }

    .d-sm-none {
      display: none !important;
    }
  }

  @media (min-width: $tablet-min-width) {
    .d-md-block {
      display: block !important;
    }

    .d-md-none {
      display: none !important;
    }
  }

  @media (min-width: $desktop-min-width) {
    .d-lg-block {
      display: block !important;
    }

    .d-lg-none {
      display: none !important;
    }
  }

  .border-and-padding {
    padding: spacing(1rem);
    border: 1px solid $color-4;
    border-radius: $roundness;
  }

  .remove-arrows-input-number {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  .cursor-pointer {
    cursor: pointer !important;
  }
}