@import '../../docs-variables';

#{$ds-scope-selector} .prop-version-tag {
  display: inline-block;
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 4px;
  color: var(--color-1);

  &.tag-new {
    background-color: var(--color-warning);
  }

  &.tag-obsolete {
    background-color: var(--color-danger);
  }
}