@import '../../../docs-variables';

#{$ds-scope-selector} .doc-container {
  padding-top: 153px;
  padding-left: 346px;
  padding-right: 64px;
  padding-bottom: 50px;

  pre {
    font-size: 2rem;
  }

  .doc-table {
    width: 100%;
    font-size: 2rem;
  }

  .doc-table,
  .doc-table th,
  .doc-table td {
    padding: 24px;
    border: 1px solid var(--color-2);
    border-collapse: collapse;
    text-align: left;
  }
}
